<template>
  <Detail />
</template>

<script>
import Detail from "./components/Detail"
export default {
    name:'addCategory',
    components: {
        Detail
    }
}
</script>

<style>

</style>